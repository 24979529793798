import React from 'react'
import { Container } from 'react-bootstrap'

const fpsbot = () => {
  return (
    <div>
        <div className='offset'></div>
        <a href='/'><i class="back-btn fa-regular fa-circle-left float-start mt-2 ms-3"></i></a> 
        
        <Container>

            <div className='project-title'>
                RL TRAINED BOT FOR SHOOTING GAMES
            </div>

            <div className='project-desc'>
                <br/>Developed as part of my final year major project.
                <br/>Used the ml-agents package in Unity to train an agent with Reinfrocement Learning to shoot enemies moving towards it.
                <br/>The enemy bots used to train the agent were given the ability to hear footsteps and move towards the noise source using a dynamic NavMesh.
                <br/>Agent was rewarded based on number of enemies shot and steps taken, and penalized for missing shots or coming in contact with the enemy.
                <br/>Implemented Curriculum Learning, that increases the difficulty of the environment gradually, when the agent reaches a certain reward threshold.

                <div className='project-images my-3'>
                    <img src='/projects/fpsbot/images/card.png' alt=""></img>
                    <img src='/projects/fpsbot/images/navmesh.png' alt=""></img>
                    {/* <video controls>
                        <source src="/projects/fpsbot/images/footsteps.mp4" type="video/mp4"></source>
                        Sorry, your browser doesn't support videos.
                    </video> */}
                </div>

                <strong>FUTURE WORK : </strong> Add weapons of Varying Range. Add Static Bots so that the agent has to move around more.
                
                <div className=''>
                    <br/><strong>SKILLS ACCQUIRED :</strong>
                    <ul className='project-skills'>
                        <li>Reinfrocement Learning</li>
                        <li>Curriculum Learning</li>
                    </ul>
                </div>

            </div>
        </Container>
    </div>
  )
}

export default fpsbot