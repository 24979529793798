import React from 'react'
import { Button, Container } from 'react-bootstrap'

const About = () => {
  return (
    <div className='about' id='about'>
      <Container className='hero'>
        <div className='pt-3 px-3 text-left name'>Aditya Kaul</div>
        <h2 className='px-3 text-left domain'>AI/ML & Game Development</h2>

        <h4 className='px-3 text-left intro'></h4>

        <Button variant='outline-primary' size="lg" className='mx-3 my-3' href="Aditya Kaul_Resume.pdf" download>Download CV</Button>
        <Button variant='primary' size="lg" className='mx-4 my-3' href="#contact">Hire Me</Button>
      </Container>
      
      <img src='bg.png' className='hidden-md about-bg img-fluid me-auto' alt="Logo"/>
    </div>
  )
}

export default About