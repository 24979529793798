import React from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'

const Skills = () => {
  return (
    <div className='skills' id='skills'>
        <h1 className='sub my-3 py-3'>SKILLS</h1>
        <Container>
        
          <div className='key'>
            <div className='basic'>Basic Knowledge</div>
            <div className='intermediate'>Intermediate Knowledge</div>
            <div className='advanced'>Advanced Knowledge</div>
          </div>
          
          <Row xs={1} md={3} className="g-3">
            <Col>
            <Card className="skill-card">
            <Card.Body>
            <Card.Title as='div' className="skill-title"><strong>Libraries and Frameworks</strong></Card.Title>
            <Card.Text as='div' className="skill-tab">
                    
                  <ul>
                    <li className='intermediate'>Pandas, Numpy</li>
                    <li className='intermediate'>scikit-learn</li>
                    <li className='intermediate'>PhaserJS</li>
                    <li className='basic'>TensorFlow</li>
                  </ul>
            
            </Card.Text>
            </Card.Body>
            </Card>
            </Col>
            <Col>
            <Card className="skill-card">
            <Card.Body>
            <Card.Title as='div' className="skill-title"><strong>Design Tools</strong></Card.Title>
            <Card.Text as='div' className="skill-tab">
                    
                  <ul>
                    <li className='intermediate'>Unity Game Engine</li>
                    <li className='intermediate'>Photoshop</li>
                    <li className='intermediate'>GIMP</li>
                  </ul>
            
            </Card.Text>
            </Card.Body>
            </Card>
            </Col>
            <Col>
            <Card className="skill-card">
            <Card.Body>
            <Card.Title as='div' className="skill-title"><strong>Languages</strong></Card.Title>
            <Card.Text as='div' className="skill-tab">
                    
                  <ul>
                    <li className='intermediate'>C/C++</li>
                    <li className='intermediate'>Python</li>
                    <li className='intermediate'>C#</li>
                    <li className='basic'>HTML, CSS, JavaScript</li>
                    <li className='basic'>Java</li>
                  </ul>
            
            </Card.Text>
            </Card.Body>
            </Card>
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default Skills