import React from 'react'
import { Container } from 'react-bootstrap'

const endlessrunner = () => {
  return (
    <div>
        <div className='offset'></div>
        <a href='/'><i class="back-btn fa-regular fa-circle-left float-start mt-2 ms-3"></i></a>
    
        <Container>
        
            <div className='project-title'>
                2D ENDLESS RUNNER
            </div>
            
            <div className='project-desc'>
                <br/>2D Platformer developed with the Unity Game Engine using assets from Michigan State University.
                <br/>Has multiple levels designed with Tilemaps as well as an endless running mode.
                <br/>Endless mode has many pre-designed sections (Prefabs) each having a start and end point.
                <br/>A section is randomly selected from a the list of prefabs and rendered in front of the current section's end point as soon as the trigger point is reached.
                <br/>The Sections already passed by the player are destroyed to conserve memory.
                <br/>Moreover, after a certain distance is travelled, all asset positions are reset to world (0, 0, 0) so that the coordinate values don't exceed memory limits.

                <div className='project-images my-3'>
                    <img src='/projects/endlessrunner/images/card.png' alt=""></img>
                </div>

                <div className=''>
                    <strong>SKILLS ACCQUIRED :</strong>
                    <ul className='project-skills'>
                        <li>Tilemaps</li>
                        <li>Infinite Level Generation</li>
                        <li>Memory Management</li>
                    </ul>
                </div>

            </div>
        </Container>
    </div>
  )
}

export default endlessrunner