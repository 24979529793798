import React from 'react'
import About from '../components/About'
import Projects from '../components/Projects'
import WorkEx from '../components/WorkEx'
import Skills from '../components/Skills'

const homepage = () => {
  return (
    <div>
        <About/>
        <WorkEx/>
        <Projects/>
        <Skills/>
    </div>
  )
}

export default homepage