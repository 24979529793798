const projects = [
  {
    _id: 'o1',
    name: 'Portfolio Website',
    image: '/projects/portfolio/images/card.png',
    description: 'Personal Website developed with HTML, CSS, Javascript, React and Bootstrap.',
    skills: 'React',
    link: 'adityakaul.com',
  },
]

export default projects