import React from 'react'
import { Container } from 'react-bootstrap'

const algotrade = () => {
  return (
    <div>
        <div className='offset'></div>
        <a href='/'><i class="back-btn fa-regular fa-circle-left float-start mt-2 ms-3"></i></a>
        
        <Container>
            
            <div className='project-title'>
                ALGORITHMIC TRADING BOT
            </div>
            
            <div className='project-desc'>
                <br/>Trading Bot developed for the Binance API to trade cryptocurrency pairs.
                <br/>Used Python libraries to perform technical analysis on historical price data.
                <br/>Perfromed feature selection on the obtained dataframe to select most rewarding indicators like RSI, MACD, Boillinger Bands etc.
                <br/>Defined optimum buy/sell points for the training data to maximize the target variable, i.e Balance.
                <br/>Trained the model on this data to predict buy/sell points for future data.
                <br/>Model performs better than simply holding BTC, also managed to remain profitable during a massive crash however there is much room for improvement.

                <div className='project-images my-3'>
                    <img src='/projects/algotrade/images/ta.png' alt=""></img>
                    <img src='/projects/algotrade/images/graph.png' alt=""></img>
                </div>

                <div className=''>
                    <strong>SKILLS ACCQUIRED :</strong>
                    <ul className='project-skills'>
                        <li>Data Mining/Cleaning</li>
                        <li>Feature Selection</li>
                        <li>Data Analysis</li>
                        <li>Performed the entire process invloved in a ML problem from gathering data to displaying the results in a visual form</li>
                    </ul>
                </div>

            </div>
        </Container>
    </div>
  )
}

export default algotrade