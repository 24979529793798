import React from 'react'
import { Container } from 'react-bootstrap'

const frams = () => {
  return (
    <div>
        <div className='offset'></div>
        <a href='/'><i class="back-btn fa-regular fa-circle-left float-start mt-2 ms-3"></i></a>
        
        <Container>

            <div className='project-title'>
                FACIAL RECOGNITION BASED ATTENDANCE MANAGEMENT SYSTEM
            </div>
            
            <div className='project-desc'>
                <br/>Attendance System developed as part of my 2nd Year Minor Project.
                <br/>Used the OpenCV Library in Python to implement Computer Vision.
                <br/>Attendance System that has the option to register students by saving their Name, Roll No. and Face data.
                <br/>Program takes around 100 photos of a student in quick succession while registering to create a dataset for the classifier.
                <br/>Upon Pressing the train button, a classifier is trained based on the data generated while registering
                <br/>When the "Take Attendance" Button is pressed, the program asks for the subject and starts recording video, detecting all the faces present in the feed.
                <br/>An Excel File is generated with the names of all registered students and their attendance (Present or Absent) depending on whether their faces were detected or not.

                <div className='project-images my-3'>
                    <img src='/projects/frams/images/details.png' alt=""></img>
                    <img src='/projects/frams/images/register.png' alt=""></img>
                    <img src='/projects/frams/images/scans.png' alt=""></img>
                    <img src='/projects/frams/images/result.png' alt=""></img>
                </div>

                <div className=''>
                    <strong>SKILLS ACCQUIRED :</strong>
                    <ul className='project-skills'>
                        <li>Computer Vision</li>
                        <li>TKinter GUI</li>
                        <li>OpenCV</li>
                    </ul>
                </div>

            </div>
        </Container>
    </div>
  )
}

export default frams