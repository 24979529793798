const projects = [
  {
    _id: 'a1',
    name: 'Facial Recognition Based Attendance Management System',
    image: '/projects/frams/images/card.png',
    description: 'Attendance System developed as part of my 2nd year minor project. Registers students\' name and face data, trains a classifier to recognize them in a video feed and mark the attendance accordingly.',
    skills: "Python",
    link: '',
  },
  {
    _id: 'a2',
    name: 'Algorithmic Trading Bot - Ongoing',
    image: '/projects/algotrade/images/card.png',
    description: 'Trading Bot designed for the Binance API to trade cryptocurrencies based on signals derived from a model trained on historic price data.',
    skills: 'Python',
    link: '',
  },
  {
    _id: 'a3',
    name: 'RL Trained Bot for Shooting Games',
    image: '/projects/fpsbot/images/card.png',
    description: 'Developed as part of the Final Year Major Project. Bot for Shooting Games developed with Reinfrocement Learning using Unity ml-agents. Trained against custom designed bots that can move towards the agent based on the sound of its footsteps.',
    skills: 'C#',
    link: '',
  },
]

export default projects