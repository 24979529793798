import React from 'react'
import { Container } from 'react-bootstrap'

const rollaball = () => {
  return (
    <div>
        <div className='offset'></div>
        <a href='/'><i class="back-btn fa-regular fa-circle-left float-start mt-2 ms-3"></i></a>
    
        <Container>
        
            <div className='project-title'>
                ROLLING BALL MAZE
            </div>
            
            <div className='project-desc'>
                <br/>Mobile Game developed as part of a freelance task.
                <br/>Designed a 3D Maze using Unity3D and assets pprovided by the Company.
                <br/>Objective of the game is to control the ball using accelerometer controls, navigate through a maze and reach the goal while avoid all obstacles in the way.
                <br/>Learned how to save player state and preferences and implemented a settings page accordingly.
                <br/>Added a sensitivity slider and multiple zoom levels for the camera.
                <br/>Also added vibration feedback on collision with the walls to give a sense of realism.

                <div className='project-images my-3'>
                    <img src='/projects/rollaball/images/card.png' alt=""></img>
                    <img src='/projects/rollaball/images/level.png' alt=""></img>
                </div>

                <div className=''>
                    <strong>SKILLS ACCQUIRED :</strong>
                    <ul className='project-skills'>
                        <li>Accelerometer Controls</li>
                        <li>Saving Player Preferences</li>
                        <li>Device Vibration Feedback</li>
                        <li>Memory Management</li>
                    </ul>
                </div>

            </div>
        </Container>
    </div>
  )
}

export default rollaball