import Header from './components/Header'
import Footer from './components/Footer'
import Contact from './components/Contact'
import Homepage from './screens/homepage'
import AlgoTrade from './projects/algotrade'
import FpsBot from './projects/fpsbot'
import Frams from './projects/frams'
import Endlessrunner from './projects/endlessrunner'
import Rollaball from './projects/rollaball'
import Planes from './projects/planes'
import Portfolio from './projects/portfolio'
import { HashRouter as Router, Route, Routes} from 'react-router-dom';


function App() {
  return (
    <>
    <Router>
      <Header/>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/project/a1' element={<Frams />} />
        <Route path='/project/a2' element={<AlgoTrade />} />
        <Route path='/project/a3' element={<FpsBot />} />
        <Route path='/project/g2' element={<Endlessrunner />} />
        <Route path='/project/g3' element={<Rollaball />} />
        <Route path='/project/g4' element={<Planes />} />
        <Route path='/project/o1' element={<Portfolio />} />
      </Routes>
      <Contact/>
      <Footer/>
    </Router>
    </>
  );
}

export default App;
