import React from 'react'
import { Container } from 'react-bootstrap'

const portfolio = () => {
  return (
    <div>
        <div className='offset'></div>
        <a href='/'><i class="back-btn fa-regular fa-circle-left float-start mt-2 ms-3"></i></a> 
        
        <Container>

            <div className='project-title'>
                PORTFOLIO WEBSITE
            </div>

            <div className='project-desc'>
                <br/>127.0.0.1
                <br/>Porfolio website developed using React, React-Router, Bootstrap, HTML, CSS and JavaScript.

                <div className='project-images my-3'>
                    <img src='/projects/portfolio/images/card.png' alt=""></img>
                </div>

            </div>
        </Container>
    </div>
  )
}

export default portfolio