import React from 'react'
import { Container } from 'react-bootstrap'

const planes = () => {
  return (
    <div>
        <div className='offset'></div>
        <a href='/'><i class="back-btn fa-regular fa-circle-left float-start mt-2 ms-3"></i></a>
    
        <Container>
        
            <div className='project-title'>
                DOGFIGHT
            </div>
            
            <div className='project-desc'>
                <br/>Android Game developed as a personal project using Unity.
                <br/>Uses On-Screen Controls to operate a fighter jet in a 2d environment with continously spawning enemies.
                <br/>Objective of the game is to survive for the longest time while eliminating enemy jets, however it is a work in progress so I might switch to a campaign system.
                <br/>The USP or the primary mechanic is that the plane is continously falling due to gravity and the user has the option to either activate the thruster and propell the plane, or shoot at the enemies, but not both simulataneously.
                <br/>Added multiple spawn point for the enemy planes to spawn in, Added a Health bar that depletes when shot.
                <br/>Added a parallax background where the scenery moves at different speeds dependin on how far back it is.
                <br/>Also added va working day-night cycle to add a little visual flair to the game.
                <br/><strong>TO DO : </strong>Imporve visual effects and player/enemy assets. Add bloom lighting effects. Add a working leaderboard to compete with people around the world. Add varying weapons and enemy types.

                <div className='project-images my-3'>
                    <video controls>
                        <source src="/projects/planes/images/play.mp4" type="video/mp4"></source>
                        Sorry, your browser doesn't support videos.
                    </video>
                    <br/>
                    <img src='/projects/planes/images/card.jpeg' alt=""></img>
                </div>

                <div className=''>
                    <strong>SKILLS ACCQUIRED :</strong>
                    <ul className='project-skills'>
                        <li>Accelerometer Controls</li>
                        <li>Saving Player Preferences</li>
                        <li>Device Vibration Feedback</li>
                        <li>Memory Management</li>
                    </ul>
                </div>

            </div>
        </Container>
    </div>
  )
}

export default planes