import React from 'react'
import { Badge, Button, Card } from 'react-bootstrap'
import{Link} from 'react-router-dom'

const Project = ({project}) => {

  return (
    <Link className="cards-link" to={`/project/${project._id}`}>
    <Card bg="dark" text="light" className='cards'>
        <img src={project.image} className="py-2 px-2" variant = 'top' alt='project'/>
        
        <Card.Body>
        
        <Card.Title as='div' className='cards-title'><strong>{project.name}</strong></Card.Title>
        
        <Card.Text as='div' className='cards-desc'>
            {project.description}
            <br/><br/>
        </Card.Text>

         
        <Badge bg="light" text="dark" className="tab-cards cards-badge">{project.skills}</Badge>
        <Button className="tab-cards cards-button" variant="primary">View Project</Button>
        </Card.Body>
    </Card>
    </Link>
  )
}

export default Project