import React from 'react'
import { Nav, Navbar, NavDropdown, Dropdown, Container } from 'react-bootstrap'

const Header = () => {
  return (
    <div>
        <Navbar fixed="top" className="navbar"bg="dark" expand="lg" variant="dark">
            <Container>
                <Navbar.Brand href="/" className='brand'>Aditya Kaul</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link className= "nav-links px-4" href="#/#cv">CV</Nav.Link>

                    {/* <Nav.Link className= "nav-links ps-4" href="#/#projects">Projects</Nav.Link> */}
                    
                    <NavDropdown className="px-4 nav-drop" title="Projects" menuVariant="dark">
                        
                        <NavDropdown className="mt-2 mx-4" title="AI Projects" menuVariant="dark" drop="end">
                            <Dropdown.Item className="mt-2 dropdown-projects" href="#/project/a1">Facial Recognition Based Attendance System</Dropdown.Item>
                            <Dropdown.Item className="mt-2 dropdown-projects" href="#/project/a2">Algorithmic Trading Bot</Dropdown.Item>
                            <Dropdown.Item className="mt-2 dropdown-projects" href="#/project/a3">RL Trained Bot for Shooting Games</Dropdown.Item>
                        </NavDropdown>

                        <NavDropdown className="mt-2 mx-4" title="Game Dev Projects" menuVariant="dark" drop="end">
                            <Dropdown.Item className="mt-2 dropdown-projects" href="#/project/a3">RL Trained Bot for Shooting Games</Dropdown.Item>
                            <Dropdown.Item className="mt-2 dropdown-projects" href="#/project/g2">2D Endless Runner</Dropdown.Item>
                            <Dropdown.Item className="mt-2 dropdown-projects" href="#/project/g3">Rolling Ball Maze</Dropdown.Item>
                            <Dropdown.Item className="mt-2 dropdown-projects" href="#/project/g4">Dogfight</Dropdown.Item>
                        </NavDropdown>

                        <NavDropdown className="mt-2 mx-4" title="Other Projects" menuVariant="dark" drop="end">
                            <Dropdown.Item className="mt-2 dropdown-projects" href="#/project/o1">Portfolio Website</Dropdown.Item>
                        </NavDropdown>
                       
                    </NavDropdown>

                    <Nav.Link className= "nav-links px-4" href="/#skills">Skills</Nav.Link>

                </Nav>
                <Nav className="ms-auto">                  
                    <Nav.Link target = "_blank" className= "nav-links px-4" href="https://github.com/callmekaul">
                        <i class="fa-brands fa-github"></i>
                    </Nav.Link>
                    <Nav.Link target = "_blank" className= "nav-links px-4" href="https://www.linkedin.com/in/callmekaul/">
                        <i class="fa-brands fa-linkedin"></i>
                    </Nav.Link>
                    <Nav.Link className= "nav-links ps-4" href = "mailto: theadityakaul@gmail.com">
                        <i class="fa-solid fa-at"></i>
                    </Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
  )
}

export default Header