import React from 'react'
import { Tabs, Tab, Container, Row, Col, Carousel, CarouselItem } from 'react-bootstrap'
import Project from './Project'
import AI_Data from '../AI_Data'
import GD_Data from '../GD_Data'
import OT_Data from '../OT_Data'

const Projects = () => {


  return (
    <div className='projects' id='projects'>
      <h1 className='sub my-3 py-3'>PROJECTS</h1>
      
      <Container className='carousel'>
        <Tabs defaultActiveKey="AI/ML" className="tabs mb-3 fs-4">
          <Tab eventKey="AI/ML" title="AI/ML">
            <Carousel>
              {AI_Data.map(project => (
                <CarouselItem key = {project._id}>
                  <Project project = {project}/>
                </CarouselItem>
              ))}
            </Carousel>
          </Tab>
          <Tab eventKey="Game Dev" title="Game Dev">
            <Carousel>
              {GD_Data.map(project => (
                <CarouselItem key = {project._id}>
                  <Project project = {project}/>
                </CarouselItem>
              ))}
            </Carousel>
          </Tab>
          <Tab eventKey="Other" title="Other">
            <Carousel>
              {OT_Data.map(project => (
                <CarouselItem key = {project._id}>
                  <Project project = {project}/>
                </CarouselItem>
              ))}
            </Carousel>
          </Tab>
        </Tabs>
      </Container>

      <Container className='tab-cards'>
        <Tabs defaultActiveKey="AI/ML" className="tabs mb-3 fs-4">
          <Tab eventKey="AI/ML" title="AI/ML">
            <Row xs={1} md={3} className="g-3">
              {AI_Data.map(project => (
                <Col key = {project._id}>
                        <Project project = {project}/>
                    </Col>
                ))}
            </Row>
          </Tab>
          <Tab eventKey="Game Dev" title="Game Dev">
            <Row xs={1} md={3} className="g-3">
              {GD_Data.map(project => (
                    <Col key = {project._id}>
                        <Project project = {project}/>
                    </Col>
                ))}
            </Row>
          </Tab>
          <Tab eventKey="Other" title="Other">
            <Row xs={1} md={3} className="g-3">
              {OT_Data.map(project => (
                    <Col key = {project._id}>
                        <Project project = {project}/>
                    </Col>
                ))}
            </Row>
          </Tab>
        </Tabs>
      </Container>
    </div>
  )
}

export default Projects