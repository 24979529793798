import React from 'react'
import { Button, Container } from 'react-bootstrap'

const Contact = () => {
  return (
    <div className='contact' id="contact">
        <h1 className='sub my-3 py-3'>CONTACT</h1>
        <Container>
            <h4 style={{color:"white"}}>Hi! Send me a Message</h4>
              
              <section class="my-3">

              <div class="row">
                <div class="col-md-9 mb-md-0 mb-5">
                  
                  <form action="https://formsubmit.co/theadityakaul@gmail.com" method="POST">
                    
                    <div class="row">
                      
                      <div class="col-md-6">
                        <div class="md-form ">
                          <input type="text" placeholder="Your Name" id="name" name="name" class="form-control my-2 py-3"></input>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="md-form ">
                            <input type="text" placeholder="Your E-Mail" id="email" name="email" class="form-control my-2 py-3"></input>
                          </div>
                        </div>
                    
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                          <div class="md-form">
                            <input type="text" placeholder="Subject" id="subject" name="subject" class="form-control my-2 py-3"></input>
                          </div>
                        </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="md-form">
                          <textarea type="text" placeholder="Message" id="message" name="message" rows="2" class="form-control md-textarea mt-2 py-4"></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="text-center text-md-left">
                        <button class="btn btn-primary mt-3" type="submit">Send</button>
                    </div>
                    <div class="status"></div>

                  </form>

                </div>
                

                
                <div class="col-md-3 text-center">
                    <ul style={{color:"white"}} class="list-unstyled mb-0">
                        <li><i class="fas fa-map-marker-alt fa-2x"></i>
                            <p>Delhi, IN</p>
                        </li>

                        <li><i class="fas fa-phone mt-4 fa-2x"></i>
                            <p>+91 99715 91478</p>
                        </li>

                        <li><i class="fas fa-envelope mt-4 fa-2x"></i>
                            <p>theadityakaul@gmail.com</p>
                        </li>
                    </ul>
                </div>

            </div>

        </section>
        </Container>
    </div>
  )
}

export default Contact