import React from 'react'
import { Container } from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';


const WorkEx = () => {
  return (
    <div className='workex' id="workex">
        <h1 className='sub my-3 py-3'>WORK EXPERIENCE</h1>
        <Container>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#7f53ac', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #7f53ac' }}
                    date="Jun 2020 - Aug 2020"
                    iconStyle={{ background: '#7f53ac', color: '#fff' }}
                >
                    
                    <h3 className="vertical-timeline-element-title">NLP Research Intern</h3>
                    <h5 className="vertical-timeline-element-subtitle">Indian Institue of Technology (IIT-BHU), Varnasi </h5>
                    <ul className='py-3'>
                        <li>Worked on Natural Language Processing, NLP</li>
                        <li>Machine Translation models that utilize byte-pair encoding for the translation of rare words</li>
                        <li>Implemented ULMFit for translation and got a consistent increase in BLEU scores</li>
                        <li>Fine-Tuned the model to work for Hindi, Marathi, Gujarati and Nepali</li>
                    </ul>

                    <a href="https://iitbhu.ac.in/" target="_blank" class="btn btn-light ms-3">Website</a>
                    <a href="https://github.com/callmekaul/Neural-Machine-Translation" target="_blank" class="btn btn-light ms-3">GitHub Repo</a>

                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: '#647dee', color: '#fff' }}
                    contentArrowStyle={{ borderRight: '7px solid  #647dee' }}
                    date="May 2020 - Aug 2020"
                    iconStyle={{ background: '#647dee', color: '#fff' }}
                
                    >
                    <h3 className="vertical-timeline-element-title">Game Tester</h3>
                    <h5 className="vertical-timeline-element-subtitle">Oneros Tech Pvt. Ltd. - Pune, Maharashtra</h5>
                    <ul className='py-3'>
                        <li>Worked as an integral part of the development team.</li>
                        <li>Wrote competent code and algorithms for optimization of game functions such as timer difficulty based on in-game data.</li>
                        <li>Performed various pre-launch and post-launch quality checks on multiple applications on the Google Play Store</li>
                        <li>Experienced firsthand all the steps involved in the development lifecycle of a game.</li>
                    </ul>

                    <a href="http://www.onerostech.com/" target="_blank" class="btn btn-light ms-3">Website</a>

                </VerticalTimelineElement>
            </VerticalTimeline>
        </Container>
    </div>
  )
}

export default WorkEx