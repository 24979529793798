const projects = [
  {
    _id: 'a3',
    name: 'RL Trained Bot for Shooting Games',
    image: '/projects/fpsbot//images/card.png',
    description: 'Developed as part of the Final Year Major Project. Bot for Shooting Games developed with Reinfrocement Learning using Unity ml-agents. Trained against custom designed bots that can move towards the agent based on the sound of its footsteps.',
    skills: 'Unity',
    link: ''
  },
  {
    _id: 'g2',
    name: '2D Endless Runner',
    image: '/projects/endlessrunner//images/card.png',
    description: 'Endless Runner Sidescroller developed with Unity. Randomly selects sections from a list of prefabs to generate infinite platforms for the player to run on while being chased.',
    skills: 'Unity',
    link: ''
  },
  {
    _id: 'g3',
    name: 'Roll-A-Ball',
    image: '/projects/rollaball//images/card.png',
    description: 'Developed for Mobile as part of an internship task using Unity3D. Uses a Gyroscope controller to move a ball around a maze containing dynamic obstacles. Also saves player state and sensitivity settings.',
    skills: 'Unity3D',
    link: ''
  },

  {
    _id: 'g4',
    name: 'Dogfight',
    image: '/projects/planes//images/card.jpeg',
    description: 'Personal Project developed for mobiles. Uses on-screen controls to shoot and move a plane while fighting infinitely spawning enemies. Used a parallax effect for the background along with a working day/night cycle.',
    skills: 'Unity',
    link: ''
  },
]

export default projects